import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Button, Divider, List, ListItem, ListItemText, ListItemIcon, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PaymentIcon from '@mui/icons-material/Payment';




function ContactDetails({ order }) {
    const [isBlurred, setIsBlurred] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleRevealDetails = () => {
        setIsProcessing(true);
        // Simuler un processus de chargement avant de révéler les détails
        setTimeout(() => {
            setIsProcessing(false);
            setIsBlurred(false);
        }, 2000); // Ajustez le temps selon vos besoins
    };
    return (
        <Box sx={{ width: '90%',  marginX:'auto'}}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Contact</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ filter: isBlurred ? 'blur(5px)' : 'none', transition: 'filter 0.3s ease', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {isProcessing ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List sx={{ width: '100%' }}>
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary="Téléphone" secondary={order.phone ? order.phone : "Non disponible"} />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Email" secondary={order.email ? order.email : "Non disponible"} />
                            </ListItem>
                        </List>
                    )}
                </AccordionDetails>
            </Accordion>

            <Divider sx={{ my: 2, width: '100%' }} />

            {isBlurred && (
                <Button
                    startIcon={<PaymentIcon />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    onClick={handleRevealDetails}
                    disabled={isProcessing}
                >
                    {isProcessing ? "Traitement..." : "Révéler les détails"}
                </Button>
            )}
        </Box>

    );
}

export default ContactDetails;
