// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FFC107', // Your primary color
        },
        secondary: {
            main: '#17A2B8', // Your secondary color
        },
        success: {
            main: '#28A745', // Your success color
        },
        error: {
            main: '#DC3545', // Your danger color
        },
        warning: {
            main: '#FFC107', // Your warning color
        },
        info: {
            main: '#29B6F6', // Your info color
        },
        background: {
            default: '#ffffff', // Your background color
        },
        text: {
            primary: '#212529', // Your text color
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif', // Your font family base
        h1: {
            fontSize: '2.5rem', // Equivalent to your font-size-large
        },
        // ... other variants
        body1: {
            fontSize: '1rem', // Your font-size-base
        },
        body2: {
            fontSize: '0.875rem', // Your font-size-small
        },
    },
    shape: {
        borderRadius: 4, // 4px is equivalent to your --border-radius
    },
    spacing: 8, // Base spacing is 8px. You can use a factor of this value for your spacing.
    transitions: {
        duration: {
            standard: 200, // 200ms is equivalent to your --transition-base duration
        },
    },
    components: {
        // For the Tab component
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '1rem', // --spacing-base
                    '&:hover': {
                        backgroundColor: '#29B6F6', // --color-info
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#FFC107', // --color-primary
                        color: '#FFFFFF', // --color-background
                    },
                },
            },
        },
        // For the Link component
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#FFC107', // --color-primary
                    textDecoration: 'none',
                    '&:hover': {
                        color: '#212529', // --color-dark
                    },
                },
            },
        },
        // Style overrides for InputBase component
        MuiInputBase: {
            styleOverrides: {
                input: {
                    height: '1.2rem',  // Set the desired height
                    padding: '10px 14px',  // Adjust padding to change the visual height of the input
                },
            },
        },
        // Alternatively, for Outlined Input which is used by TextField variant="outlined"
        // MuiOutlinedInput: {
        //     styleOverrides: {
        //         input: {
        //             padding: '10px 12px',  // Default padding for standard size
        //             '&.MuiInputBase-inputSizeSmall': {
        //                 padding: '10.5px 14px',  // Adjust for size "small"
        //             }
        //         },
        //         notchedOutline: {
        //             top: 0,  // Fixes an issue where the outline might not align
        //         },
        //     },
        // },
        // For Input with an underline (standard TextField)
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',  // Adjust underline style if needed
                    },
                },
            },
        },

        // Define overrides for other components as needed
    },
});

export default theme;
