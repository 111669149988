import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import FindTrip from './pages/FindTrip';
import PostTrip from './pages/PostTrip';
import UserProfile from './pages/UserProfile';
import BottomNav from './components/BottomNab'; // Make sure the file name is correct
import PostTrip2 from './pages/PostTrip2';
import History from './pages/History';
import Messages from './pages/Messages';
import TripDetails from './pages/TripDetails';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import LandingPage from './pages/LandingPage';
import IdentityVerification from './pages/IdentityVerification';

// Assuming Header is a component you want to render inside BrowserRouter
import Header from './components/Header';
import './styles.css';
import TripsList from './pages/TripsList';
import FeedbackForm from './pages/FeedbackForm';
import SMSConfirmation from './pages/SMSConfirmation';
import TermsAndConditionsModal from './components/TermsAndConditionsModal';
import ForgotPasswordView from './pages/ForgotPasswordView';
import PaymentOptions from './components/PaymentOptions';


import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme you created
import CssBaseline from '@mui/material/CssBaseline';
import ChatWindow from './pages/ChatWindow';
import UserProfileEdit from './pages/UserProfileEdit';

// This should be a separate component if it relies on useNavigate and useLocation
function SwipeableRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const [index, setIndex] = useState(0);

  const paths = [
    '/signup',
    '/identity-verification',
    '/search',
    '/',
    '/post',
    '/post-2',
    '/history',
    '/messages',
    '/profile',
    '/forgot-password',
    '/sms-confirmation/:phoneNumber',
    '/payement-options/:kilos',
    '/chatwindow',
    '/editprofil'

  ];

  useEffect(() => {
    const currentIndex = paths.findIndex(path => path === location.pathname);
    setIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [location, paths]);

  const handleChangeIndex = (index) => {
    navigate(paths[index], { replace: true });
  };

  return (
    <SwipeableViews index={index} onChangeIndex={handleChangeIndex} enableMouseEvents>
      <SignUp />
      <IdentityVerification />
      <FindTrip />
    </SwipeableViews>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set a timeout to change the loading state
    const timer = setTimeout(() => setLoading(false), 5000); // 5 seconds

    // Clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // If loading, show the LandingPage, else render the app's routes
  if (loading) {

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LandingPage />

      </ThemeProvider >)
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<SwipeableRoutes />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/identity-verification" element={<IdentityVerification />} />
          <Route path="/search" element={<FindTrip searchInitiated={false} />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={<FindTrip searchInitiated={false} />} /> */}
          {/* <Route path="/" element={<SMSConfirmation />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/post" element={<PostTrip />} />
          <Route path="/post-2" element={<PostTrip2 />} />
          <Route path="/history" element={<History />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/trip-details/:tripId" element={<TripDetails />} />
          <Route path="/trips-list" element={<TripsList />} />
          <Route path="/forgot-password" element={<ForgotPasswordView />} />
          <Route path="/sms-confirmation/:phoneNumber" element={<SMSConfirmation />} />
          <Route path="/payement-options/:kilos" element={<PaymentOptions  />} />
          <Route path="/chatwindow" element={<ChatWindow  />} />
          <Route path="/editprofil" element={<UserProfileEdit />} />
        </Routes>
        <BottomNav />
      </BrowserRouter>
    </ThemeProvider>
  );
}

// Using the new ReactDOM.createRoot API for React 18
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);

export default App;