import React, { useState } from 'react';
import { Box, List, ListItem, ListItemAvatar, Avatar, Paper, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const activeGPS = [
  { id: 1, name: 'GP 1', status: 'confirmé', source: 'Paris', destination: 'Lyon' },
  { id: 2, name: 'GP 2', status: 'arrivée', source: 'Marseille', destination: 'Nice' },
  
];


function ActiveGPSList() {
  const [selectedGPS, setSelectedGPS] = useState(null);

  const handleSelectGPS = (gps) => {
    setSelectedGPS(gps);
    console.log('GPS sélectionné:', gps.name);
  };

  return (
    <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
      <Paper elevation={3} sx={{ display: 'flex', overflowX: 'auto', marginBottom:'20px', borderRadius: '8px' }}>
        <List sx={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}>
          {activeGPS.map((gps) => (
            <ListItem 
              button 
              key={gps.id} 
              selected={selectedGPS?.id === gps.id} 
              onClick={() => handleSelectGPS(gps)}
              sx={{ minWidth: '240px', justifyContent: 'center', padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <Typography variant="subtitle1" noWrap>{gps.name}</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                {gps.source} → {gps.destination}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.875rem' }}>
                Statut: {gps.status}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

export default ActiveGPSList;
