
import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, TextField, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function UserPreferences() {
  const [preferences, setPreferences] = useState([
    { id: 'ecoFriendly', label: 'Proche de vous', checked: false },
    { id: 'fastDelivery', label: 'Livraison rapide', checked: false },
    { id: 'lowCost', label: 'Coût réduit', checked: false },
    { id: 'highRating', label: 'Haute réputation', checked: false },
  ]);
  
  const [preferredDestination, setPreferredDestination] = useState('');

  const handleCheckboxChange = (changedPreference) => {
    const updatedPreferences = preferences.map(preference =>
      preference.id === changedPreference.id ? { ...preference, checked: !preference.checked } : preference
    );
    setPreferences(updatedPreferences);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(preferences);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setPreferences(items);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="preferences">
        {(provided) => (
          <FormGroup {...provided.droppableProps} ref={provided.innerRef}>
            <Typography>Glissez et déposez pour réorganiser vos préférences :</Typography>
            {preferences.map(({ id, label, checked }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <FormControlLabel
                      control={<Checkbox checked={checked} onChange={() => handleCheckboxChange({ id, checked })} name={id} />}
                      label={label}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <TextField
              label="Destination préférée"
              value={preferredDestination}
              onChange={(e) => setPreferredDestination(e.target.value)}
              fullWidth
              margin="normal"
            />
          </FormGroup>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default UserPreferences;



