import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, FormControlLabel, Checkbox, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import TermsAndConditionsModal from '../components/TermsAndConditionsModal'; // Import the modal component


function SignUp({ onSignup }) {
  const [userDetails, setUserDetails] = useState({
    phoneNumber: '',
    email: '',
    password: '',
    acceptTerms: false,
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, checked, type } = event.target;
    setUserDetails({
      ...userDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!userDetails.acceptTerms) {
      alert("Vous devez accepter les CGU pour continuer.");
      return;
    }
    // onSignup(userDetails);
    // Logique d'inscription ici (appel API, validation, etc.)
    navigate(`/sms-confirmation/${userDetails.phoneNumber}`);
  };

  const onSignIn = (event) => {
    event.preventDefault();
    navigate('/login');
  };



  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        Inscription
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phoneNumber"
          label="Téléphone portable"
          name="phoneNumber"
          autoComplete="tel"
          value={userDetails.phoneNumber}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          value={userDetails.email}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Mot de passe"
          type="password"
          id="password"
          autoComplete="new-password"
          value={userDetails.password}
          onChange={handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="acceptTerms"
              color="primary"
              checked={userDetails.acceptTerms}
              onChange={handleChange}
            />
          }
          label={(
            <>
              J'accepte les
              <Link href="#" underline="hover" onClick={handleOpenModal} > CGU</Link>
              {' '}de GPackGO.
            </>
          )}
        />

        <TermsAndConditionsModal open={modalOpen} handleClose={handleCloseModal} />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Créer mon compte
        </Button>
        {/* Additional section for navigation to sign-in page */}
        <Typography variant="body2" sx={{ textAlign: 'center', my: 2 }}>
          Vous avez déjà un compte ? {' '}
          <Link href="#" onClick={onSignIn} underline="hover">
            Se connecter
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}

export default SignUp;
