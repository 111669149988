import React, { useState, useEffect } from 'react';
import {
    TextField,
    Stack,
    IconButton,
    Box,
    Button,
    Menu,
    MenuItem,
    InputAdornment,
    Divider
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SortingOptions from './SortingOptions';
import TuneIcon from '@mui/icons-material/Tune';
import MyLocationIcon from '@mui/icons-material/MyLocation'; // Import the MyLocationIcon





function TripSearch({ depart, arrivee, onSearch }) {
    const [preferences, setPreferences] = useState([
        { id: 'ecoFriendly', label: 'Proche de vous', checked: false },
        { id: 'fastDelivery', label: 'Livraison rapide', checked: false },
        { id: 'lowCost', label: 'Coût réduit', checked: false },
        { id: 'highRating', label: 'Haute réputation', checked: false },
    ]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [selectedTime, setSelectedTime] = useState('Dans');


    const handleClose = (days) => {
        setSelectedTime(days + ' Jrs');
        setAnchorEl(null);
    };

    const [departValue, setDepartValue] = useState(depart);
    const [arriveeValue, setArriveeValue] = useState(arrivee);

    // Synchronize state with props
    useEffect(() => {
        setDepartValue(depart);
        setArriveeValue(arrivee);
    }, [depart, arrivee]);

    // useEffect(() => {
    //     if (departValue && arriveeValue) {
    //         onSearch(departValue, arriveeValue);
    //     }
    // }, [departValue, arriveeValue]); // Dependencies are the state variables, not the props


    const handleSwap = () => {
        setDepartValue((prevDepart) => {
            setArriveeValue(prevDepart);
            return arriveeValue;
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent the default form submission
        if (departValue.trim() && arriveeValue.trim()) {
            onSearch(departValue, arriveeValue);
        }
    };


    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                // Convertir les coordonnées en adresse ici si nécessaire
                setDepartValue(prevState => ({
                    ...prevState,
                    departValue: `Lat: ${latitude}, Lon: ${longitude}` // Ceci est un exemple, devrait être converti en adresse réelle via une API comme Google Maps
                }));
            });
        }
    };




    return (

        <Box sx={{
            width: '100%',
            marginX: 'auto',
        }}>

            <form onSubmit={handleSubmit}>
                <Stack spacing={1}>
                    {/* Depart TextField */}
                    <TextField
                        fullWidth
                        label="Départ"
                        variant="outlined"
                        // size="small"
                        value={departValue}
                        onChange={(e) => setDepartValue(e.target.value)}
                        InputProps={{
                            sx: { borderRadius: '50px' },
                            endAdornment: (
                                <IconButton color="primary" onClick={handleSwap}>
                                    <SwapVertIcon />
                                </IconButton>
                            ),
                        }}
                    />

                    {/* Arrivee TextField */}
                    <TextField
                        fullWidth
                        label="Arrivée"
                        variant="outlined"
                        // size="small"
                        value={arriveeValue}
                        onChange={(e) => setArriveeValue(e.target.value)}
                        InputProps={{
                            sx: { borderRadius: '50px' },
                        }}
                    />

                    {/* Additional Options and Buttons */}
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button
                            color="primary"
                            startIcon={<MyLocationIcon />}
                            sx={{ textTransform: 'none', fontSize: '14px' }}
                            type="button" // Prevents form submission
                            onClick={handleGetCurrentLocation}
                        >
                            Utiliser votre position
                        </Button>
                        <SortingOptions preferences={preferences} setPreferences={setPreferences} />
                    </Box>
                </Stack>
            </form>

        </Box>


    );
}

export default TripSearch;
