import React from 'react';
import { Box, Paper, TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ActiveGPSList from './ActiveGPSList'; // Assurez-vous que le chemin d'import est correct
import ChatPage from './ChatPage'; // Assurez-vous que le chemin d'import est correct

function Messages() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                p: 1,
                height: '100vh', // Prend toute la hauteur de la fenêtre
                width: '100%', // Prend toute la largeur
            }}
        >
            {/* Liste des GPS actifs, centrée et défilable horizontalement */}
            <Box sx={{ width: '100%' }}>
                <ActiveGPSList />
            </Box>
            <ChatPage />

        </Box>
    );
}

export default Messages;
