import React, {useState } from 'react';
import {  IconButton, Container, Box, Radio, RadioGroup, FormControlLabel, FormControl, Button, Typography, Divider, useTheme } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import CreditCardIcon from '@mui/icons-material/CreditCard'; // Add this
import AppleIcon from '@mui/icons-material/Apple'; // Add this
import PayPalImg from '../assets/images/paypal.png'; // Add this
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router-dom';

const PaymentOptions = ({ amount }) => {
  
  const [selectedValue, setSelectedValue] = useState('mastercard');
  const theme = useTheme();
  const navigate = useNavigate();
  const { kilos } = useParams();

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePayment = () => {
    console.log(`Paiement de ${kilos} € par ${selectedValue}`);
    // Insert payment logic here
  };

  function IconByMethod({ method }) {
    switch (method) {
      case 'mastercard':
        return <CreditCardIcon />;
      case 'bankcard':
        return <CreditCardIcon />;
      case 'applepay':
        return <AppleIcon />;
      case 'paypal':
        return <Box component="img" src={PayPalImg} sx={{ width: 24, height: 24 }} />;
      default:
        return null;
    }
  }

  function labelWithIcon(method) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <IconByMethod method={method} />
        {labelByMethod(method)}
      </Box>
    );
  }

  function labelByMethod(method) {
    switch (method) {
      case 'mastercard':
        return 'Mastercard ....6947';
      case 'bankcard':
        return 'Carte bancaire';
      case 'paypal':
        return 'Apple Pay';
      case 'applepay':
        return 'PayPal';
      default:
        return '';
    }
  }



  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: '100px', }}>
      <IconButton onClick={() => navigate(-1)} sx={{ mb: theme.spacing, paddingLeft: 0 }}>
        <ArrowBackIcon color="primary" />
      </IconButton>

      <Typography variant="title1" >
        Mode de paiement
      </Typography>

      <FormControl component="fieldset" sx={{ width: '100%', my: 2 }}>
        <RadioGroup name="paymentMethod" value={selectedValue} onChange={handleChange}>
          {['mastercard', 'bankcard', 'paypal', 'applepay'].map((method, index) => (
            <React.Fragment key={method}>
              {index === 1 && <Divider sx={{ mb: 2, mt:4 }}>Nouveau mode de paiement</Divider>} {/* Ajout du Divider après le premier élément */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 1 }}>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>

                  <IconByMethod method={method} color="action" />
                  {labelByMethod(method)}

                </Typography>
                <FormControlLabel
                  value={method}
                  control={<Radio />}
                  label=""
                  sx={{ m: 0, p: 0, position: 'absolute', right: 0 }}
                />
              </Box>
            </React.Fragment>
          ))}
        </RadioGroup>
      </FormControl>

      <Divider sx={{ my: 1 }} />


      <Typography variant="caption" sx={{ p: 2, display: 'block', textAlign: 'center' }}>
        Vous serez remboursé si votre demande n'est pas acceptée
      </Typography>
      <Divider sx={{ bgcolor: theme.palette.primary.light, my: 2 }} />
      <Box sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<PaymentIcon />}
          onClick={handlePayment}
          sx={{ py: 2, boxShadow: 'none', borderRadius: theme.shape.borderRadius }}
        >
          Payer {kilos} €
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentOptions;
