import React, { useState } from 'react';
import { Box, Container, TextField, Button, Checkbox, FormControlLabel, Grid, Tooltip } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation'; // Icone pour la géolocalisation
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomStepper from '../components/CustomStepper';
import { useNavigate } from 'react-router-dom';

function PostTrip2() {
    const [userInfo, setUserInfo] = useState({
        pickupAddress: '',
        saveAsDefault: false,
        paymentMethod: '',
        acceptTerms: false,
    });

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value, checked, type } = event.target;
        setUserInfo(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                // Convertir les coordonnées en adresse ici si nécessaire
                setUserInfo(prevState => ({
                    ...prevState,
                    pickupAddress: `Lat: ${latitude}, Lon: ${longitude}` // Ceci est un exemple, devrait être converti en adresse réelle via une API comme Google Maps
                }));
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(userInfo);
        navigate('/post-2');
    };

    const handleBack = () => {
        navigate(-1);
    };
    

    return (
        <Container maxWidth="sm">
            <CustomStepper activeStep={true} sx={{ mb: 4 }} />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Adresse de dépôt du colis"
                            name="pickupAddress"
                            type="text"
                            value={userInfo.pickupAddress}
                            onChange={handleInputChange}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Button
                                color="primary"
                                startIcon={<MyLocationIcon />}
                                sx={{ textTransform: 'none', fontSize: '14px' }}
                                type="button" // Prevents form submission
                                onClick={handleGetCurrentLocation}
                            >
                                Utiliser votre position
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={userInfo.saveAsDefault}
                                    // onChange={handleInputChange}
                                    name="saveAsDefault"
                                />
                            }
                            label="Modifier comme adresse par défaut"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox name="paymentOnPlatform" />}
                            label="Paiement via la plateforme"
                        />
                       
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={<Checkbox name="acceptTerms"  size='small'  />}
                            label="J'accepte les termes et conditions"
                        />
                    </Grid>
                    <Grid item xs={12} my={2}>
                        <Button onClick={handleBack} variant="outlined" color="primary">
                            Retour
                        </Button>
                        <Button type="submit" variant="contained" color="primary" sx={{ float: 'right' }}>
                            Poster
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default PostTrip2;
