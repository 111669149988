import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Container,
  IconButton,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrainIcon from '@mui/icons-material/Train';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

function RecentSearchesList({ onTripSelect }) {
  const recentSearches = [
    { id: 1, depart: 'Paris', arrival: 'Nouakchott', },
    { id: 2, depart: 'Dakar', arrival: 'Paris', },
    // Add more items here
  ];

  return (
    <Container sx={{ padding: 0 }}>
      <Typography variant="h6" sx={{ padding: '16px ' }}>
        Recherches récentes
      </Typography>
      <List component="nav" aria-label="recent searches">
        {recentSearches.map((search, index) => (
          <React.Fragment key={search.id}>
            <ListItem button onClick={() => onTripSelect(search.depart, search.arrival)}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={`${search.depart} → ${search.arrival}`} />

              <IconButton edge="end" aria-label="comments">
                <FlightTakeoffIcon />
              </IconButton>
            </ListItem>

            {index < recentSearches.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
}

export default RecentSearchesList;
