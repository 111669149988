import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star'; // For the star outline
import StarHalfIcon from '@mui/icons-material/StarHalf'; // For half-filled star
import StarBorderIcon from '@mui/icons-material/StarBorder'; // For the empty star

const RatingTag = ({ rating }) => {
   // Ensure rating is treated as a number
   const numericRating = parseFloat(rating);

   // Calculate the filled portion of the star
   const filledStarPercentage = (numericRating % 1).toFixed(2) * 100;
 
   // Determine the color based on the numeric rating value
   const starColor = numericRating >= 4 ? 'success.main' : numericRating >= 3 ? 'warning.main' : 'error.main';
   
  return (
    <Box 
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#e0e0e0', // Light gray background
        borderRadius: '50px', // Rounded corners for the tag
        padding: '2px 6px', // Adjust padding as needed
        gap: '4px', // Space between icon and text
      
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <StarBorderIcon sx={{ color: starColor, fontSize: '18px' }} />
        {filledStarPercentage > 0 && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: `${filledStarPercentage}%`,
              overflow: 'hidden',
              display: 'flex'
            }}
          >
            <StarIcon sx={{ color: starColor, fontSize: '18px' }} />
          </Box>
        )}
      </Box>
      <Typography variant="body2" component="span" sx={{ fontWeight: 'bold', fontSize:"10px" }}>
        {rating.toFixed(1)}
      </Typography>
    </Box>
  );
};

export default RatingTag;
