import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const TermsAndConditionsModal = ({ open, handleClose }) => {
  // Modal content can be loaded from a state, prop, or directly written here
  const termsAndConditionsText = `Full terms and conditions text goes here. This text can be quite long and include all relevant details for the usage of the application. The user should be able to scroll through this text to read everything.`;

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
      <DialogTitle>
        Terms et Conditions
        <IconButton onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" component="p" style={{ whiteSpace: 'pre-wrap' }}>
          {termsAndConditionsText}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsModal;
