import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star'; // Pour l'étoile pleine
import StarHalfIcon from '@mui/icons-material/StarHalf'; // Pour la demi-étoile
import StarBorderIcon from '@mui/icons-material/StarBorder'; // Pour l'étoile vide

const StarRating = ({ rating }) => {
  const numericRating = parseFloat(rating);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Rating
        name="customized-rating"
        value={numericRating}
        precision={0.1} // Précision pour permettre l'affichage de demi-étoiles
        readOnly // Rendre le composant de notation en lecture seule
        // size="small" // Réduit la taille des étoiles
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        icon={<StarIcon fontSize="inherit" />}
        halfIcon={<StarHalfIcon fontSize="inherit" />}
      />
    </Box>
  );
};

export default StarRating;
