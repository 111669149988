import React, { useState } from 'react';
import { Link, Box, Button, Typography, styled, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Icône pour le bouton de téléchargement
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icône pour le fichier sélectionné
import { NavigateBefore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .3)',
  background: theme.palette.background.paper, // Use theme's background color
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  color: theme.palette.getContrastText(theme.palette.primary.main),
  backgroundColor: theme.palette.primary.main, // Use theme's primary color
  '&:hover': {
    backgroundColor: theme.palette.primary.dark, // Darken the primary color on hover
  },
  textTransform: 'none',
  fontSize: '0.875rem',
}));

const LinkButton = styled(Link)(({ theme }) => ({
  margin: theme.spacing(4),
  fontSize: '0.875rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: theme.palette.secondary.main, // Use theme's secondary color
  '&:hover': {
    textDecoration: 'underline',
    color: theme.palette.secondary.dark, // Darken the secondary color on hover
  },
}));


function IdentityVerification() {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Veuillez sélectionner un fichier.");
      return;
    }
    console.log("Fichier envoyé pour vérification :", selectedFile.name);
  };

  const navigate = useNavigate();

  const handleSkip = (event) => {
    event.preventDefault();
    navigate('/search');
  };

  const handleDisconnect = (event) => {
    event.preventDefault();
    navigate('/login');
  };

  return (
    <StyledPaper>
      <Typography component="h1" variant="h5" color="text.primary" sx={{ fontWeight: 'bold' }}>
        Vérification de l'identité
      </Typography>
      <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>
        Pour la sécurité de tous, nous exigeons une vérification d'identité. Veuillez télécharger une copie de votre document d'identité (CNI, passeport, permis de conduire).
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%', textAlign: 'center' }}>
        <StyledButton variant="contained" component="label" startIcon={<CloudUploadIcon />}>
          Télécharger un document
          <input type="file" hidden onChange={handleFileChange} />
        </StyledButton>
        {selectedFile && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
            <InsertDriveFileIcon sx={{ mr: 1 }} />
            <Typography variant="body2">
              Fichier sélectionné : {selectedFile.name}
            </Typography>
          </Box>
        )}
        <StyledButton type="submit" fullWidth variant="contained">
          Soumettre pour vérification
        </StyledButton>
      </Box>

      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', }}>

        <LinkButton onClick={handleDisconnect} component="button">
          Se déconnecter
        </LinkButton>

        <LinkButton onClick={handleSkip} component="button">
          Ignorer
        </LinkButton>

      </Box>
    </StyledPaper>
  );
}

export default IdentityVerification;
