import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Box, TextField, Typography, Container } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ForgotPasswordView = () => {
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Empêcher le rechargement de la page
        // Insérer ici la logique pour traiter la demande de réinitialisation du mot de passe
        console.log('Demande de réinitialisation du mot de passe pour:', email);
        // Ajouter la logique pour informer l'utilisateur que l'email a été envoyé
    };

    return (
        <Container component="main" maxWidth="xs">
            <IconButton onClick={() => navigate(-1)} >
                <ArrowBackIcon color='primary' />
            </IconButton>

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Réinitialisation du mot de passe
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Adresse e-mail"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Envoyer le lien de réinitialisation
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default ForgotPasswordView;
