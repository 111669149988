import React, { useState, useEffect } from 'react';
import { useTheme, Box, List, ListItem, ListItemText, Divider, Typography, Collapse, ListItemIcon, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightIcon from '@mui/icons-material/Flight';
import ChatIcon from '@mui/icons-material/Chat'; // Importing the chat icon
import { useNavigate } from 'react-router-dom';

function TripsList({ trips }) {
  const [open, setOpen] = useState({});
  const navigate = useNavigate();
  const theme = useTheme();


  useEffect(() => {
    // const initialOpenState = trips.reduce((acc, trip) => ({ ...acc, [trip.status]: false }), {});
    // setOpen(initialOpenState);
    const initialOpenState = trips.reduce((acc, trip) => {
      acc[trip.status] = trip.status === "En cours" || trip.status === "En attente";
      return acc;
    }, {});
    setOpen(initialOpenState);
  }, [trips]);

  const groupedTrips = trips.reduce((acc, trip) => {
    const { status } = trip;
    acc[status] = acc[status] || [];
    acc[status].push(trip);
    return acc;
  }, {});

  const handleClick = (status) => {
    setOpen(prev => ({ ...prev, [status]: !prev[status] }));
  };

  const navigateToChat = (id) => {
    navigate("/chatwindow");
  }

  const getStatusIcon = (status) => {
    switch (status) {
      case 'En cours':
        return <FlightIcon />;
      case 'Terminé':
        return <FlightLandIcon />;
      case 'En attente':
        return <FlightTakeoffIcon />;
      default:
        return <FlightIcon />;
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {Object.keys(groupedTrips).sort().map((status) => (
        <List key={status} sx={{ width: '100%', }}>
          <ListItem button onClick={() => handleClick(status)}>
            <ListItemIcon>
              {getStatusIcon(status)}
            </ListItemIcon>
            <ListItemText primary={status} />
            {open[status] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={open[status]} timeout="auto" unmountOnExit>
            {groupedTrips[status].map((trip, index) => (
              <React.Fragment key={trip.id}>
                {index > 0 && <Divider variant="inset" component="li" />}
                <ListItem sx={{ pl: 4, display: 'flex', alignItems: 'center' }}>
                  <ListItemText
                    primary={`${trip.departure} → ${trip.arrival}`}
                    secondary={`Date d'arrivée: ${trip.date_arrival}`}
                  />

                  <IconButton
                    onClick={() => navigateToChat()}
                    edge="end"
                    size="small"
                    sx={{
                      color: theme.palette.primary.main, // Use primary color for the icon
                      '&:hover': {
                        backgroundColor: theme.palette.action.hover, // Use theme hover color
                        color: theme.palette.primary.dark // Darken the icon color on hover
                      },
                      transition: 'color 0.2s ease-in-out', // Smooth transition for color change
                    }}
                  >
                    <ChatIcon /> {/* Chat icon button */}
                  </IconButton>
                </ListItem>
              </React.Fragment>
            ))}
          </Collapse>
        </List>
      ))}
    </Box>
  );
}

export default TripsList;
