import React, { useState } from 'react';
import { CardContent, IconButton, Container, TextField, Button, Typography, Avatar, Grid, Switch, FormControlLabel } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function UserProfileEdit() {
    const theme = useTheme();
    const navigate = useNavigate();

    const [user, setUser] = useState({
        fullName: '',
        email: '',
        phone: '',
        receiveNewsletters: false
    });

    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        setUser(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Profile updated:', user);
        // Logique de mise à jour du profil ici
    };

    return (
        <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
            <IconButton onClick={() => navigate(-1)} sx={{ mb: theme.spacing, mr:2 }}>
                <ArrowBackIcon color="primary" /> 
                <Typography variant="h6"  sx={{ mb: theme.spacing, ml:2 }}> Modifier votre profil</Typography>  
            </IconButton>

            <CardContent>
            
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Nom complet"
                                name="fullName"
                                value={user.fullName}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Téléphone"
                                name="phone"
                                value={user.phone}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                type="email"
                                value={user.email}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Adresse de dépôt des colis"
                                name="adresse"
                                value={user.phone}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={user.receiveNewsletters}
                                        onChange={handleChange}
                                        name="receiveNewsletters"
                                        color="primary"
                                    />
                                }
                                label="Recevoir les newsletters"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                loading={false}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Enregistrer
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </CardContent>
        </Container>
    );
}

export default UserProfileEdit;
