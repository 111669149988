// import React, { useState } from 'react';
// import { Box, Tab, Tabs, Typography, Container } from '@mui/material';
// import PersonalInfoForm from './PersonalInfoForm';
// import UserPreferences from './UserPreferences';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// function UserProfile() {
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <Container maxWidth="md">
//       <Box sx={{ width: '100%' }}>
//         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//           <Tabs value={value} onChange={handleChange} aria-label="profile tabs">
//             <Tab label="Informations Personnelles" {...a11yProps(0)} />
//             <Tab label="Préférences" {...a11yProps(1)} />
//           </Tabs>
//         </Box>
//         <TabPanel value={value} index={0}>
//           <PersonalInfoForm/>
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <UserPreferences/>
//         </TabPanel>
//       </Box>
//     </Container>
//   );
// }

// export default UserProfile;


import React from 'react';
import {Chip, Box, Container, Typography, Avatar, Button, Paper, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarRating from '../components/StarRating';
import { useNavigate } from 'react-router-dom';


function UserProfile() {
  const navigate=useNavigate();
  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 2, mt: 2, mb: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Avatar sx={{ width: 100, height: 100 }} src="/static/images/avatar/1.jpg" />
          <Typography variant="h5">Segue</Typography> <Chip icon={<VerifiedIcon />} label="Vérifié" color="success" />
          {/* <Typography variant="body2" color="text.secondary">Software Developer</Typography> */}
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <StarRating rating={parseFloat(4.5)} />
            <Typography variant="body1">4.5</Typography>
          </Box>
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="jdiallo@gamil.com" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="+1234567890" />
          </ListItem>
        </List>
        
        <Divider sx={{ my: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="outlined" startIcon={<LockIcon />}>
            Paramètres
          </Button>

          <Button onClick={() => navigate('/editprofil')} variant="contained" startIcon={<EditIcon />}>Modifier </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default UserProfile;
