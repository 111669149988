import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PostTrip from './pages/PostTrip';
import FindTrip from './pages/FindTrip';
import PersonalInfoForm from './pages/PersonalInfoForm';
import BottomNav from './components/BottomNab';
import { Navigate,BrowserRouter, Routes, Route } from 'react-router-dom';


// Correctly create a root
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

// Render the <App /> component without specifying the container again
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
