import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Link, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login({ onLogin, onNavigateToSignup }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();
    // onLogin(email, password);
    navigate('/identity-verification');
    // Here you can put the login logic
  };

  const handleSignUp = (event) => {
    event.preventDefault();
    // onLogin(email, password);
    navigate('/signup');
    // Here you can put the login logic
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    // onLogin(email, password);
    navigate('/forgot-password');
    // Here you can put the login logic
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        Vous avez déjà un compte ?
      </Typography>
      <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Mot de passe"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link href="#"  onClick={handleForgotPassword} variant="body2" sx={{ display: 'block', textAlign: 'right', mb: 2 }}>
          Mot de passe oublié ?
        </Link>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
        >
          Se connecter
        </Button>
        <Divider sx={{ my: 2 }}>OU</Divider>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleSignUp}
        >
          Créer mon compte
        </Button>
      </Box>
    </Container>
  );
}

export default Login;
