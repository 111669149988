import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Container } from '@mui/material';
import CustomStepper from '../components/CustomStepper';
import { useNavigate } from 'react-router-dom';

function PostTrip() {
    const [trip, setTrip] = useState({
        departure: '',
        arrival: '',
        date_departure: '',
        date_arrival: '',
        spaceAvailable: '',
        pricePerKg: '',
        extraComment: '',
    });

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/post-2');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTrip(prev => ({ ...prev, [name]: value }));
    };

    return (
        <Container maxWidth="sm" sx={{ mb: 8 }}>
            <CustomStepper activeStep={0} sx={{ mb: 4 }} />

            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Lieu de départ"
                            margin="normal"
                            required
                            value={trip.departure}
                            onChange={(handleChange)}
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Lieu d'arrivée"
                            margin="normal"
                            required
                            value={trip.arrival}
                            onChange={(handleChange)}
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="date"
                            name="date_departure"
                            label="Date de départ"
                            value={trip.date_departure}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="date"
                            name="date_arrival"
                            label="Date d'arrivée"
                            value={trip.date_arrival}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            required
                            InputLabelProps={{ shrink: true }}
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            name="spaceAvailable"
                            label="Disponibilité (kg)"
                            value={trip.spaceAvailable}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            required
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            type="number"
                            name="pricePerKg"
                            label="Prix par kg (€)"
                            value={trip.pricePerKg}
                            onChange={handleChange}
                            margin="normal"
                            fullWidth
                            required
                            sx={{ input: { borderRadius: '8px' } }}
                        />
                    </Grid>
                </Grid>

                <TextField
                    fullWidth
                    name="extraComment"
                    label="Commentaire supplémentaire (facultatif)"
                    value={trip.extraComment}
                    onChange={handleChange}
                    margin="normal"
                    multiline
                    rows={2}
                    sx={{ input: { borderRadius: '8px' } }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                    <Button type="submit" variant="contained" color="primary">
                        Suivant
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

export default PostTrip;
