import React from 'react';
import { Stepper, Step, StepLabel, Box } from '@mui/material';

function CustomStepper({ activeStep }) {
  return (
    <Box sx={{ width: '100%', marginBottom: 2, marginTop: 2 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Créer l'offre</StepLabel>
        </Step>
        <Step>
          <StepLabel>Finaliser</StepLabel>
        </Step>
      </Stepper>
    </Box>
  );
}

export default CustomStepper;
