import React, { useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme, TextField, IconButton, Card, CardContent, Box, Typography, Paper, Container, CircularProgress, Button, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import ContactDetails from './ContactDetails';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'; // Pour le départ
import FlightLandIcon from '@mui/icons-material/FlightLand'; // Pour l'arrivée
import EventIcon from '@mui/icons-material/Event'; // Pour les dates
import DescriptionIcon from '@mui/icons-material/Description'; // Pour la description
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // Pour le prix
import LuggageIcon from '@mui/icons-material/Luggage'; // Pour l'espace disponible
import InfoIcon from '@mui/icons-material/Info'; // Pour la distance
import StarRateIcon from '@mui/icons-material/StarRate'; // Pour la note
import StarRating from '../components/StarRating';

import PaymentIcon from '@mui/icons-material/Payment';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NearMeIcon from '@mui/icons-material/NearMe'; // Assuming proximity is the focus
import PaymentOptions from '../components/PaymentOptions';

import StarBorderIcon from '@mui/icons-material/StarBorder'; // Assuming a star icon for the rating
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // For verified profile


// Simulons une fonction pour récupérer les détails du voyage depuis un API ou un store
const fetchTripDetails = async (tripId) => {
    // Remplacer ceci par votre logique de récupération des données réelles
    return new Promise((resolve) => setTimeout(() => resolve({
        id: tripId,
        departure: "Paris",
        arrival: "Lyon",
        date_departure: "2024-03-15",
        date_arrivee: "2024-03-15",
        time: "15:00",
        price: "50€",
        spaceAvailable: "2 places",
        pricePerKg: "10$/kg",
        rating: 4.5,
        fullDescription: "Voyage agréable, 4 valises, air france, medicament, telephones, ....;",
    }), 100));
};


function TripDetails() {
    const [kilos, setKilos] = useState('');
    const [error, setError] = useState(false);

    const [trip, setTrip] = useState(null);
    const [loading, setLoading] = useState(true);
    const { tripId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();


    useEffect(() => {
        const loadTripDetails = async () => {
            const details = await fetchTripDetails(tripId);
            setTrip(details);
            setLoading(false);
        };

        loadTripDetails();
    }, [tripId]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color='primary' />
            </Box>
        );
    }



    const handleKilosChange = (event) => {
        const value = event.target.value;
        setKilos(value);
        // Validate the input: value must be a number and greater than or equal to 1
        setError(value < 1 || value === '');
    };


    // Handle the reservation action
    const handleReserve = (event) => {
        event.preventDefault();
        if (kilos < 1 || kilos === '') {
            setError(true);
        } else {
            setError(false);
            console.log('Submitting Kilos:', kilos);
            navigate(`/payement-options/${kilos}`);
        }
    };



    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: '100px' }}>
            <IconButton onClick={() => navigate(-1)} sx={{ mb: theme.spacing }}>
                <ArrowBackIcon color="primary" />
            </IconButton>

            <CardContent>
                {/* Header with date and price */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                        {trip.departure} → {trip.arrival}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        16,89 €/kg
                    </Typography>
                </Box>

                {/* Departure and arrival details */}
                <Typography variant="body1" gutterBottom>
                    <EventIcon sx={{
                        verticalAlign: 'middle',
                        mr: 1,
                        fontSize: '1rem',
                        color: theme.palette.text.secondary
                    }} />
                    Départ: {trip.date_departure}
                </Typography>

                <Typography variant="body1" gutterBottom>
                    <EventIcon sx={{ verticalAlign: 'middle', mr: 1, fontSize: '1rem', color: theme.palette.text.secondary }} />
                    Arrivée: {trip.date_arrivee}
                </Typography>

                {/* Space and proximity */}
                <Typography variant="body1" gutterBottom>
                    <LuggageIcon sx={{ verticalAlign: 'middle', mr: 1, fontSize: '1rem', color: theme.palette.text.secondary }} />
                    Disponibilité: {trip.spaceAvailable} kg
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <NearMeIcon sx={{ verticalAlign: 'middle', mr: 1, fontSize: '1rem', color: theme.palette.text.secondary }} />
                    Proximité: {trip.proximity} km
                </Typography>

                {/* Full description */}
                <Box
                    sx={{
                        display: 'flex',
                        my: 2,
                        alignItems: 'flex-start',
                        p: 1, // Adds padding inside the box for better spacing
                        backgroundColor: theme.palette.action.hover, // Use a background color from the theme designed for hover actions which is typically subtle
                        borderRadius: theme.shape.borderRadius, // Use theme's border radius for consistency
                    }}
                >
                    <InfoIcon sx={{ mr: 1, color: theme.palette.info.main }} /> {/* Use theme's info color for the icon */}
                    <Typography variant="body2" sx={{ color: theme.palette.text.primary }}>
                        {trip.fullDescription}
                    </Typography>
                </Box>
                {/* Rating and verified profile */}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StarRating rating={parseFloat(trip.rating)} />
                    <Typography variant="body2" sx={{ ml: 2 }}>
                        ({10} avis)
                    </Typography>
                </Box>

                <Divider sx={{ my: 2 }} />


                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: 2, gap: 2 }}>
                    <TextField
                        type="number"
                        label="Nombre de kilos"
                        // variant="outlined"
                        value={kilos}
                        size="small"
                        onChange={handleKilosChange}
                        InputProps={{ step: 1, min: 1 }} // Only allow whole numbers, and a minimum of 1 kilo
                        sx={{ width: '50%' }} // Adjusted font size using theme
                        required
                        
                    />
                    <Button
                        startIcon={<PaymentIcon />}
                        onClick={handleReserve}
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={error || kilos === ''}
                    >
                        Réserver
                    </Button >
                </Box>

                {/* Contact Details (uncomment if needed) */}
                {/* <ContactDetails order={fetchTripDetails} /> */}
            </CardContent>

            {/* Kilos input and reservation button */}

        </Container>
    );
}

export default TripDetails;
