import React from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';

function HeaderFindTrip({ setSearchInitiated, setDeparture, setDestination }) {
    const handleRefreshClick = () => {
        window.location.reload();
    };

    const handleBackClick = () => {
        setSearchInitiated(false); // Directly sets searchInitiated to false on click
        setDeparture('');
        setDestination('');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0',
                mb:"10px"
            }}
        >
            <IconButton onClick={handleBackClick}>
                <ArrowBackIcon />
            </IconButton>
            <IconButton onClick={handleRefreshClick}>
                <RefreshIcon />
            </IconButton>
        </Box>
    );
}

export default HeaderFindTrip;
