import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    useTheme,
    Stack,
    IconButton,
    Button,
    Divider
} from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RatingTag from '../components/RatingTag';
import LuggageIcon from '@mui/icons-material/Luggage';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/SwapVert';
import TripSearch from '../components/TripSearch';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';

import RecentSearchesList from './RecentSearchesList'
import SearchTripResults from './SearchTripResults';
import HeaderFindTrip from '../components/HeaderFindTrip';

const trips = [
    {
        id: 1,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "3",
        rating: "4.3"
    },
    {
        id: 2,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "5",
        rating: "3.4"
    },

    {
        id: 3,
        departure: "Bamako",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "4",
        spaceAvailable: "10",
        distance: "2",
        rating: "3.4"
    },

    {
        id: 4,
        departure: "Rosso",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "9",
        spaceAvailable: "100",
        distance: "1",
        rating: "4.9"
    },
    {
        id: 5,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "5",
        rating: "3.4"
    },

    {
        id: 6,
        departure: "Bamako",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "4",
        spaceAvailable: "10",
        distance: "2",
        rating: "3.4"
    },

    {
        id: 7,
        departure: "Rosso",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "9",
        spaceAvailable: "100",
        distance: "1",
        rating: "4.9"
    },



];

function FindTrip({ searchInitiated: initialSearchInitiated }) {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    const [departure, setDeparture] = useState('');
    const [destination, setDestination] = useState('');
    const [filteredTrips, setFilteredTrips] = useState([]); // Assuming `trips` is defined elsewhere

    const [searchInitiated, setSearchInitiated] = useState(initialSearchInitiated);

    const handleSearchChange = (value) => {
        setSearchQuery(value);
        const lowercasedValue = value.toLowerCase();
        // Assuming `trips` is fetched or otherwise available here
        const newFilteredTrips = trips.filter(trip =>
            trip.arrival.toLowerCase().includes(lowercasedValue)
        );
        setFilteredTrips(newFilteredTrips);
    };

    const handleTripSelect = (depart, arrival) => {
        setDeparture(depart);
        setDestination(arrival);
        setSearchInitiated(true);
    };

    const handleSwapLocations = () => {
        setDeparture(destination);
        setDestination(departure);
    };

    const handleListItemClick = (tripId) => {
        navigate(`/trip-details/${tripId}`);
    };

    const redirectToPost = () => {
        navigate('/post/');
    };

    const bottomNavigationHeight = '100px'; // Adjust as needed

    return (
        <Container maxWidth={false} sx={{ padding: 0, mb: bottomNavigationHeight }}>
            {searchInitiated && <HeaderFindTrip setSearchInitiated={setSearchInitiated} setDeparture={setDeparture} setDestination={setDestination} sx={{ mb: '20px' }}/>}
            <TripSearch depart={departure} arrivee={destination} onSearch={() => { }} />
            {!searchInitiated ? (
                <RecentSearchesList onTripSelect={handleTripSelect} />
            ) : (
                <SearchTripResults searchParams={{ depart: departure, arrivee: destination }} />
            )}
            <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: '5em', right: '2em' }} onClick={redirectToPost}>
                <AddIcon />
            </Fab>
        </Container>
    );
}

export default FindTrip;


