import React, { useState } from 'react';
import {
    Container,
    Typography,
    TextField,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar,
    useTheme,
    Stack,
    IconButton,
    Button,
    Divider,
    Card,
    CardHeader,
    CardContent,
    CardActions,
} from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RatingTag from '../components/RatingTag';
import LuggageIcon from '@mui/icons-material/Luggage';
import PlaceIcon from '@mui/icons-material/Place';
import AccessTimeIcon from '@mui/icons-material/SwapVert';
import TripSearch from '../components/TripSearch';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';

import RecentSearchesList from './RecentSearchesList'

const trips = [
    {
        id: 1,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "3",
        rating: "4.3"
    },
    {
        id: 2,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "5",
        rating: "3.4"
    },

    {
        id: 3,
        departure: "Bamako",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "4",
        spaceAvailable: "10",
        distance: "2",
        rating: "3.4"
    },

    {
        id: 4,
        departure: "Rosso",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "9",
        spaceAvailable: "100",
        distance: "1",
        rating: "4.9"
    },
    {
        id: 5,
        departure: "Nouakchott",
        arrival: "Paris",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "5",
        spaceAvailable: "10",
        distance: "5",
        rating: "3.4"
    },

    {
        id: 6,
        departure: "Bamako",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "4",
        spaceAvailable: "10",
        distance: "2",
        rating: "3.4"
    },

    {
        id: 7,
        departure: "Rosso",
        arrival: "Dakar",
        date_departure: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        date_arrival: "2024-03-01", // La date au format ISO (vous pouvez ajuster le format selon vos besoins)
        shortDescription: "Vol direct sans escale", // Une courte description
        fullDescription: "Vol direct sans escale. Repas inclus, 2 bagages autorisés.", // Description complète
        pricePerKg: "9",
        spaceAvailable: "100",
        distance: "1",
        rating: "4.9"
    },



];

function SearchTripResults({ depart, arrivee }) {
    const theme = useTheme();


    const handleSearchChange = (event, value) => {
        setSearchQuery(value);
        const lowercasedValue = value.toLowerCase();
        const newFilteredTrips = trips.filter(trip =>
            trip.arrival.toLowerCase().includes(lowercasedValue)
        );
        setFilteredTrips(newFilteredTrips);
    };

    const formatDate = (dateStr) => {
        return new Date(dateStr).toLocaleDateString('fr-FR', {
            day: '2-digit', month: 'short'
        }).replace('.', ''); // Removes the dot after the month abbreviation
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [departure, setDeparture] = useState(depart);
    const [destination, setDestination] = useState(arrivee);
    const [filteredTrips, setFilteredTrips] = useState(trips);


    const handleUseCurrentLocation = () => {
        // ... your logic to get the current location ...
    };

    const handleSwapLocations = () => {
        setDeparture(destination);
        setDestination(departure);
    };

    const navigate = useNavigate();



    const handleListItemClick = (tripId) => {
        navigate(`/trip-details/${tripId}`);
    };



    const redirectToPost = () => {
        navigate('/post/');
    };


    return (
        <Container sx={{ padding: 0 }}>
            <List>
                {filteredTrips.map((trip, index) => (
                    <React.Fragment key={trip.id}>
                        <Card
                            onClick={() => handleListItemClick(trip.id)}
                            sx={{ bgcolor: 'background.paper', marginBottom: theme.spacing(2) }}
                        >
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                                        <FlightTakeoffIcon />
                                    </Avatar>
                                }
                                action={
                                    <RatingTag rating={parseFloat(trip.rating)} />
                                }
                                title={<Typography variant="subtitle1">{trip.departure} → {trip.arrival}</Typography>}
                                subheader={
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.2 }}>
                                            <EventIcon sx={{ fontSize: '1rem' }} />
                                            {`${formatDate(trip.date_departure)} - ${formatDate(trip.date_arrival)}`}
                                        </Typography>
                                    </Box>
                                }
                            />
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        Ville
                                        <PlaceIcon sx={{ fontSize: '1rem', ml: 1 }} />
                                        {trip.distance}km
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <LuggageIcon sx={{ fontSize: '1rem' }} />
                                        {trip.spaceAvailable}kg {' |'}
                                        <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold', ml: 1 }}>
                                            {trip.pricePerKg}€/kg
                                        </Typography>
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </React.Fragment>
                ))}
            </List>
        </Container>
    );
};


export default SearchTripResults;


