import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import AddBoxIcon from '@mui/icons-material/AddBox';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MessageIcon from '@mui/icons-material/Message';

import './BottomNav.css'; // Import the CSS file

function BottomNav() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // Redirection based on the index of newValue
    const routes = ['/search', '/post', '/history', '/profile'];
    navigate(routes[newValue]);
  };

  return (
    <BottomNavigation className='bottomNav' value={value} onChange={handleChange} showLabels sx={{ width: '100%', position: 'fixed', bottom: 0 }}>
      <BottomNavigationAction label="GPs" icon={<SearchIcon />} sx={getNavItemStyle(value === 0)} />
      <BottomNavigationAction label="Poster" icon={<AddBoxIcon />} sx={getNavItemStyle(value === 1)} />
      <BottomNavigationAction label="Mes GPs" icon={<FlightIcon />} sx={getNavItemStyle(value === 2)} />
      <BottomNavigationAction label="Profil" icon={<AccountCircleIcon />} sx={getNavItemStyle(value === 3)} />
    </BottomNavigation>
  );
}

// Utility function to get the style based on if the item is active
function getNavItemStyle(isActive) {
  return {
    '.MuiBottomNavigationAction-label': { fontWeight: isActive ? 'bold' : 'normal' },
    '.MuiBottomNavigationAction-icon': {
      filter: isActive ? 'none' : 'blur(2px)',
    }
  };
}

export default BottomNav;
