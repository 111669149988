import React from 'react';
import { Typography, Box, Container, useTheme } from '@mui/material';
import logo from "../assets/images/logo.png"; // Ensure this path is correct
import './LandingPage.css'; // Import your CSS for the custom loading animation

function LandingPage() {
    const theme = useTheme(); // Access the theme to use its values directly

    return (
        <Container component="main" maxWidth="sm" sx={{
            mt: '50%', // Adjust with vh for more consistent centering
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Box
                component="img"
                sx={{
                    maxHeight: '50%',
                    maxWidth: '50%',
                    width: 'auto',
                    height: 'auto',
                    
                }}
                alt="GPackGO logo"
                src={logo}
            />

            {/* <Typography variant="h4" sx={{
                textAlign: 'center',
                my: 4,
                fontWeight: 'bold',
                fontSize: '2rem',
                color: theme.palette.text.primary
            }}>
                GP
                <Typography component="span" fontSize='2rem' sx={{ color: theme.palette.primary.main }}>
                    ack
                </Typography>
                <Typography component="span" fontWeight='bold' sx={{ color: theme.palette.primary.main }}>
                    GO
                </Typography>
            </Typography> */}

            <Typography variant="h4" sx={{
                textAlign: 'center',
                my: 4,
                fontWeight: 'bold',
                fontSize: '2rem',
                color: theme.palette.text.primary,
                background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.text.secondary})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: '2px 2px 8px rgba(0,0,0,0.2)'
            }}>
                GPack
                <Typography component="span" sx={{ color: theme.palette.primary.main, opacity: 1 }}>
                    GO
                </Typography>
            </Typography>

            <Box className="ellipsis-loader">
                <span></span><span></span><span></span>
            </Box>

        </Container>
    );
}

export default LandingPage;
