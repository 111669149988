import React, { useState } from 'react';
import { useTheme, Box, TextField, Button, List, ListItem, ListItemText, Divider, IconButton, Typography, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FlightIcon from '@mui/icons-material/Flight'; // Import to show an icon representing the trip
import { useNavigate } from 'react-router-dom';

function ChatWindow({ tripDetails }) { // Assuming tripDetails is passed as a prop
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const navigate = useNavigate();
    const theme = useTheme();

    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            setMessages([...messages, newMessage]);
            setNewMessage('');
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSendMessage();
            event.preventDefault();
        }
    };

    return (
        <Container maxWidth="sm" sx={{ height: '90vh', display: 'flex', flexDirection: 'column', mb: 2, padding:0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: theme.palette.background.paper }}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
                    <ArrowBackIcon color="primary" />
                </IconButton>
                <Typography variant="body2" sx={{ flexGrow: 1,  }}>Paris → Nouakchott</Typography>
                <Typography variant="body2" color="text.secondary">02/05/1995</Typography>
            </Box>
            <Divider sx={{ my: 1 }}/>
            <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
                {messages.map((message, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={message} />
                    </ListItem>
                ))}
            </List>
            {/* <Divider /> */}
            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
                <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <IconButton color="primary" onClick={handleSendMessage}>
                    <SendIcon />
                </IconButton>
            </Box>
        </Container>
    );
}

export default ChatWindow;
