import React from 'react';
import { useTheme, List, ListItem, ListItemText, Divider, Typography, ListItemIcon } from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment'; // Icône pour la liste des paiements
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';

function PaymentsList({ payments }) {
    const theme = useTheme();
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {payments.map((payment, index) => (
                <React.Fragment key={payment.id}>
                    {index > 0 && <Divider  />}
                    <ListItem alignItems="flex-start" secondaryAction={
                        // <IconButton edge="end" aria-label="download" onClick={() => { /* Fonction pour gérer le téléchargement du paiement spécifique */ }}>
                        //     <DownloadIcon />
                        // </IconButton>
                        <IconButton
                            edge="end"
                            size="small"
                            sx={{
                                color: theme.palette.primary.main, // Use primary color for the icon
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover, // Use theme hover color
                                    color: theme.palette.primary.dark // Darken the icon color on hover
                                },
                                transition: 'color 0.2s ease-in-out', // Smooth transition for color change
                            }}
                        >
                            <DownloadIcon />
                        </IconButton>
                    }>
                        {/* <ListItemIcon>
                            <PaymentIcon />
                        </ListItemIcon> */}
                        <ListItemText
                            primary={`Paiement N° 08`}
                            secondary={`${payment.amount} - ${payment.date}`}
                        />
                    </ListItem>
                </React.Fragment>
            ))}
        </List>


    );
}

export default PaymentsList;