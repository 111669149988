import React, { useState } from 'react';
import { Box, Container, Typography, TextField, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';



const StyledPaper = styled(Paper)(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    padding: theme.spacing(2),
    overflowY: 'auto',
    marginBottom: theme.spacing(2),
}));

const StyledInputBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    bottom:'0',
    marginBottom: theme.spacing(10)
}));

// const StyledPaper = styled(Box)(({ theme }) => ({
//     flexGrow: 1,
//     overflowY: 'auto',
//     padding: theme.spacing(2),
//     marginBottom: theme.spacing(2),
//   }));

//   const StyledInputBox = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: theme.spacing(1),
//   }));

function ChatPage() {
    const [messages, setMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');

    const sendMessage = () => {
        if (!currentMessage.trim()) return;
        setMessages([...messages, { id: Date.now(), text: currentMessage }]);
        setCurrentMessage('');
    };

    return (
        <Container maxWidth="sm" sx={{height: '100vh', display: 'flex', flexDirection: 'column', }}>
            <StyledPaper>
                <List>
                    {messages.map((message) => (
                        <ListItem key={message.id} alignItems="flex-start">
                            <ListItemText
                                primary={<Typography variant="body1" sx={{ color: '#555', backgroundColor: '#f0f0f0', borderRadius: '10px', padding: '8px' }}>{message.text}</Typography>}
                            />
                        </ListItem>
                    ))}
                </List>
            </StyledPaper>
            <StyledInputBox component="form" onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Écrire un message..."
                    value={currentMessage}
                    onChange={(e) => setCurrentMessage(e.target.value)}
                    sx={{ mr: 1, backgroundColor: '#fff' }}
                />
                <IconButton color="primary" onClick={sendMessage}>
                    <SendIcon />
                </IconButton>
            </StyledInputBox>
        </Container>
    );
}

export default ChatPage;
