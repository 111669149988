import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, Container, Fab } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat'; // Icone pour le chat
import AddIcon from '@mui/icons-material/Add'; // Icone pour ajouter de nouvelles actions
import TripsList from './TripsList';
import UserPreferences from './UserPreferences';
import PaymentsList from './PaymentsList';
import { useNavigate } from 'react-router-dom';


function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function History() {
  const [value, setValue] = useState(0);
  
  const navigate=useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToChat = () => {
    navigate("/chatwindow");
  };

  const tripsData = [
    { id: 1, departure: 'NDB', arrival: 'Paris', date_departure: '2024-03-01', date_arrival: '2024-03-02', status: 'En attente' },
    { id: 2, departure: 'Rosso', arrival: 'Paris', date_departure: '2024-03-01', date_arrival: '2024-03-02', status: 'En cours' },
    { id: 3, departure: 'Dakar', arrival: 'Lyon', date_departure: '2024-04-15', date_arrival: '2024-04-16', status: 'Terminé' },
    // Ajoutez d'autres voyages ici...
  ];


  const paymentsData = [
    { id: 1, beneficiary: 'John Doe', amount: '100€', date: '2024-03-01', status: 'Traité' },
    { id: 2, beneficiary: 'Jane Doe', amount: '200€', date: '2024-04-01', status: 'En attente' },
    // Ajoutez d'autres paiements ici...
  ];

  return (
    <Container maxWidth="xl" sx={{ mb: 2, padding: 0 }}>
      <Box  sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="navigation tabs example"
          centered // This will center the tabs
          variant="fullWidth" // This ensures each tab takes up equal space
        >
          <Tab label="Voyages" {...a11yProps(0)} />
          <Tab label="Paiements" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel sx={{width: '100%'}} value={value} index={0}>
        <TripsList trips={tripsData} sx={{width: '100%'}}  />
      </TabPanel>
      <TabPanel variant="fullWidth" value={value} index={1}>
        <PaymentsList payments={paymentsData} />
      </TabPanel>

      {/* <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: '5em', right: '2em' }} >
        <ChatIcon onClick={goToChat} />
      </Fab> */}
    </Container>
  );
}

export default History;
