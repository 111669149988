import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Button, TextField, Typography, Container, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


function SMSConfirmation() {
  const { phoneNumber } = useParams(); // Extract phoneNumber from the URL
  const [verificationCode, setVerificationCode] = useState('');

  const navigate = useNavigate();

  const handleVerificationCodeChange = (event) => {
    setVerificationCode(event.target.value);
  };

  const handleSave = () => {
    // console.log("Code enregistré :", verificationCode);
    navigate('/identity-verification')
  };

  const handleResendCode = () => {
    console.log("Renvoi du code de vérification");
  };

  const handleModifyPhoneNumber = () => {
    console.log("Modification du numéro de téléphone");
  };

  const handleLogout = () => {
    navigate(-1);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>

      {/* <IconButton onClick={() => navigate(-1)} sx={{ mb: 2 }}>
        <ArrowBackIcon color='primary' />
      </IconButton> */}

      <Typography component="h1" variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        Confirmation par SMS
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography sx={{ mb: 2 }}>
          Merci de saisir le code de confirmation envoyé par SMS au {phoneNumber}.
          {/* {' '}<Link component="button" onClick={handleModifyPhoneNumber} underline="hover">
            Cliquez ici
          </Link> si vous souhaitez modifier votre numéro de téléphone. */}
        </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="verificationCode"
          label="Code de vérification"
          name="verificationCode"
          autoComplete="one-time-code"
          value={verificationCode}
          onChange={handleVerificationCodeChange}
          placeholder="Exemple: 123456"
        />

        <Typography sx={{ mb: 2 }}>
          Vous ne recevez pas le SMS ? {' '}
          <Link component="button" onClick={handleResendCode} underline="hover">
            Recevoir le code à nouveau
          </Link>
        </Typography>

        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 2, mb: 2 }}
          onClick={handleSave}
        >
          Enregistrer
        </Button>

        <Button
          fullWidth
          sx={{ mt: 2, mb: 2 }}
          onClick={handleLogout}
          variant="outlined"
        >
          Se déconnecter
        </Button>
      </Box>
    </Container>
  );
}

export default SMSConfirmation;
