import React, { useState } from 'react';
import { Switch, Button, IconButton, Dialog, DialogTitle, DialogContent, FormGroup, FormControlLabel, Checkbox, useTheme } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

function SortingOptions() {
    const [preferences, setPreferences] = useState([
        { id: 'ecoFriendly', label: 'Proche de vous', checked: false },
        { id: 'fastDelivery', label: 'Livraison rapide', checked: false },
        { id: 'lowCost', label: 'Coût réduit', checked: false },
        { id: 'highRating', label: 'Haute réputation', checked: false },
    ]);

    const [openDialog, setOpenDialog] = useState(false);
    const theme = useTheme(); // Use the useTheme hook to access the theme

    const toggleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const handleChange = (event) => {
        const { name, checked } = event.target;
        setPreferences((currentPreferences) =>
            currentPreferences.map((preference) =>
                preference.id === name ? { ...preference, checked: checked } : preference
            )
        );
    };

    return (
        <>
            <IconButton color="primary" onClick={toggleDialog}>
                <TuneIcon />
            </IconButton>
            <Dialog open={openDialog} onClose={toggleDialog}>
                <DialogTitle color="primary">Options de Tri</DialogTitle>
                <DialogContent>
                    <FormGroup>
                        {preferences.map((preference) => (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={preference.checked}
                                        onChange={handleChange}
                                        name={preference.id}
                                        color="primary" // Use the primary color from the theme for the switch
                                    />
                                }
                                label={preference.label}
                                key={preference.id}
                                sx={{
                                    color: theme.palette.text.primary // Dynamically use the primary text color from the theme
                                }}
                            />
                        ))}
                    </FormGroup>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SortingOptions;
